import React, { useState } from "react"
import Layout from "@components/layout"
import Meta from "@components/meta"
import * as Heading from "@components/heading"
import { Link } from "gatsby"
import Chevron from "@svgs/chevron.svg";

const Contact = () => {
  return (
    <Layout>
      <Meta
        title="問い合わせ"
        desc="木原興業へのお問合せ"
      />{ }

      <Heading.H1 text="問い合わせ" />
      <div className="px-5 mb-12 lg:max-w-4xl lg:mx-auto">
          <Heading.H3 text="採用に関するお問合せ" />
          <p>採用に関するお問合せは採用情報ページからお問合せください</p>
          <Link to="/recruit" className="p-5 flex items-center">
            <Chevron className="w-3 h-3 text-primary" />
            <span className="text-primary">採用情報</span>
          </Link>
          <Heading.H3 text="一般のお問合せ" />
          <p>電機機械・器具のご相談、ご依頼についてのお問い合わせ。こちらの電話番号までお問合せください。</p>
          <div className="p-5">
            <p>岡山本社　：086-225-2291</p>
            <p>大阪支店　：06-6262-0971</p>
            <p>今治支店　：0898-41-9280</p>
            <p>倉敷営業所：086-425-2371</p>
          </div>
      </div>
    </Layout>
  )
}

export default Contact;